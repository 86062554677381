"use strict";

const moment = require("moment");
const {IS_BACKEND} = require("../generic/common_utils");
const {LOG_STYLES} = require("./common_log_styles");

// const IS_BUILD_SYSTEM = "@is_build_system@" === "true";
const IS_BUILD_SYSTEM = true;
const IS_LOCAL = process.env.BARE !== "true" && (process.env.IS_LOCAL || "@deploy_env@" === "local");

/**
 * @type {ILogLevels}
 */
const LOG_LEVEL = {
  VERBOSE: {name: "Verbose", value: 1, color: LOG_STYLES.green, frameColor: LOG_STYLES.bgGreen, icon: "✔"},
  DEBUG: {name: "Debug", value: 2, color: LOG_STYLES.magenta, frameColor: LOG_STYLES.bgMagenta, icon: "⚡"},
  INFO: {name: "Info", value: 4, color: LOG_STYLES.cyan, frameColor: LOG_STYLES.bgBlue, icon: "(i)"},
  WARN: {name: "Warning", value: 8, color: LOG_STYLES.yellow, frameColor: LOG_STYLES.bgYellow, icon: "⚠"},
  ERROR: {name: "Error", value: 16, color: LOG_STYLES.red, frameColor: LOG_STYLES.bgRed, icon: "⚠"},
  OUTPUT: {name: `Output`, value: "-1", color: LOG_STYLES.white, frameColor: LOG_STYLES.bgWhite, icon: ">>"},
};

const LOG_CONFIG = {
  level: LOG_LEVEL.DEBUG,
  dateFormat: "HH:mm:ss.SSS",
  neutralColor: LOG_STYLES.white,
  dimmedColor: LOG_STYLES.grey,
  dateColor: LOG_STYLES.grey,
  separatorColor: LOG_STYLES.cyan,
  separator: "::",
  errorStackTraceColor: LOG_STYLES.yellow,
  maxCachedIdentifiers: 4096,
  randomColors: [
    LOG_STYLES.red,
    LOG_STYLES.green,
    LOG_STYLES.yellow,
    LOG_STYLES.magenta,
    LOG_STYLES.cyan,
  ],
  maxJSONLength: IS_BACKEND && IS_LOCAL ? 4096 : 10240,
  formatJSON: !IS_BUILD_SYSTEM,
  suspiciousStringThreshold: 500,
};

/**
 * @typedef LoggerFunction {(message?: any, ...optionalParams: any[]) => void}
 */

/**
 * @typedef LoggerCallback {(function(): string) => void}
 */

/**
 * @typedef ILogger
 *
 * @property {boolean} isError Indicates whether {@link LOG_LEVEL.ERROR} logs are enabled
 * @property {LoggerCallback} error Logs the specified message and parameters at {@link LOG_LEVEL.ERROR} level
 * using a callback as the log input. By doing that, you ensure what is inside the callback will only be called
 * if the log level matches
 *
 * @property {boolean} isWarn Indicates whether {@link LOG_LEVEL.WARN} logs are enabled
 * @property {LoggerCallback} warn Logs the specified message and parameters at {@link LOG_LEVEL.WARN} level
 * using a callback as the log input. By doing that, you ensure what is inside the callback will only be called
 * if the log level matches
 *
 * @property {boolean} isVerbose Indicates whether {@link LOG_LEVEL.VERBOSE} logs are enabled
 * @property {LoggerCallback} verbose Logs the specified message and parameters at {@link LOG_LEVEL.VERBOSE} level
 * using a callback as the log input. By doing that, you ensure what is inside the callback will only be called
 * if the log level matches
 *
 * @property {boolean} isInfo Indicates whether {@link LOG_LEVEL.INFO} logs are enabled
 * @property {LoggerCallback} info Logs the specified message and parameters at {@link LOG_LEVEL.INFO} level
 * using a callback as the log input. By doing that, you ensure what is inside the callback will only be called
 * if the log level matches
 *
 * @property {LoggerFunction} logDebug Logs the specified message and parameters at {@link LOG_LEVEL.DEBUG} level
 * @property {boolean} isDebug Indicates whether {@link LOG_LEVEL.DEBUG} logs are enabled
 * @property {LoggerCallback} debug Logs the specified message and parameters at {@link LOG_LEVEL.DEBUG} level
 * using a callback as the log input. By doing that, you ensure what is inside the callback will only be called
 * if the log level matches
 * @property {LoggerCallback} local Logs the specified locally only at the {@link LOG_LEVEL.INFO} level
 * using a callback as the log input. By doing that, you ensure what is inside the callback will only be called
 * if the log level matches
 * @property {LoggerCallback} printJumbotron Logs as {@link LOG_LEVEL.INFO} a jumbotron-like message.
 */

/**
 * @typedef ILogLevel {{name: string, value: number, color: ILogColor, icon: string, frameColor: ILogColor}}
 */

/**
 * @typedef ILogLevels {{ERROR: ILogLevel, VERBOSE: ILogLevel, INFO: ILogLevel, DEBUG: ILogLevel, WARN: ILogLevel, OUTPUT: ILogLevel}}
 */

/**
 * @typedef ILogGroup
 * @property {ILogLevel} level
 * @property {string} name
 * @property {boolean} enabled
 */

/**
 * NOTE: lpires - Apr 18, 2020
 * The original code in {@link CommonDB} was passing {@link console.log} as the sequelize logger if this was NOT a build system.
 * Now, it will receive the {@link logVerbose} method for the group {@link LOG_GROUP.CommonDB}.
 *
 * Is that really needed? If so, I think this deserves a comment here to explain why.
 *
 * @type {ILogLevel}
 */
const DEFAULT_COMMON_DB_LOG_LEVEL = LOG_LEVEL.DEBUG;

/**
 * Defines the different logging levels per feature group
 *
 * NOTE: Ideally, we should never commit a value as VERBOSE here,
 * except for cases in which a feature needs to be debugged.
 *
 * In such cases, Please state below why it's verbose (with your name and the date)
 * @enum {ILogGroup}
 */
const LOG_GROUP = {
  Admin: {name: "Admin", enabled: true, level: LOG_LEVEL.INFO},
  AntiVirus: {name: "AntiVirus", enabled: true, level: LOG_LEVEL.INFO},
  Attachments: {name: "Attachments", enabled: true, level: LOG_LEVEL.INFO},
  AWS: {name: "AWS", enabled: true, level: LOG_LEVEL.INFO},
  Build: {name: "Build", enabled: true, level: LOG_LEVEL.INFO},
  CommonDB: {
    name: "CommonDB",
    enabled: true,
    level: !IS_BUILD_SYSTEM && !IS_LOCAL ? DEFAULT_COMMON_DB_LOG_LEVEL : LOG_LEVEL.INFO
  },
  ClientDB: {name: "ClientDB", enabled: true, level: LOG_LEVEL.INFO},
  ClientServices: {name: "ClientServices", enabled: true, level: LOG_LEVEL.INFO},
  Cognito: {name: "Cognito", enabled: true, level: LOG_LEVEL.INFO},
  Dashboard: {name: "Dashboard", enabled: true, level: LOG_LEVEL.INFO},
  DatabaseHooks: {name: "DatabaseHooks", enabled: true, level: LOG_LEVEL.INFO},
  DataVerification: {name: "DataVerification", enabled: true, level: LOG_LEVEL.INFO},
  Documents: {name: "Documents", enabled: true, level: LOG_LEVEL.INFO},
  ConfigurableTables: {name: "ConfigurableTables", enabled: true, level: LOG_LEVEL.INFO},
  Editables: {name: "Editables", enabled: true, level: LOG_LEVEL.INFO},
  Email: {name: "Email", enabled: true, level: LOG_LEVEL.VERBOSE},
  Entities: {name: "Entities", enabled: true, level: LOG_LEVEL.INFO},
  Framework: {name: "Framework", enabled: true, level: LOG_LEVEL.INFO},
  Generator: {name: "Generator", enabled: true, level: LOG_LEVEL.INFO},
  HomePage: {name: "HomePage", enabled: true, level: LOG_LEVEL.INFO},
  Import: {name: "Import", enabled: true, level: LOG_LEVEL.INFO},
  CoAImport: {name: "CoAImport", enabled: true, level: LOG_LEVEL.INFO},
  ImportExport: {name: "ImportExport", enabled: true, level: LOG_LEVEL.INFO},
  Injection: {name: "Injection", enabled: true, level: LOG_LEVEL.INFO},
  JSONAttribute: {name: "JSONAttribute", enabled: true, level: LOG_LEVEL.INFO},
  Locks: {name: "Locks", enabled: true, level: LOG_LEVEL.INFO},
  Migrations: {name: "Migrations", enabled: true, level: LOG_LEVEL.DEBUG},
  Models: {name: "Models", enabled: true, level: LOG_LEVEL.DEBUG},
  Notification: {name: "Notification", enabled: true, level: LOG_LEVEL.INFO},
  Onboarding: {name: "Onboarding", enabled: true, level: LOG_LEVEL.INFO},
  ProcessExplorer: {name: "ProcessExplorer", enabled: true, level: LOG_LEVEL.INFO},
  QueryBuilder: {name: "QueryBuilder", enabled: true, level: LOG_LEVEL.INFO},
  React: {name: "React", enabled: true, level: LOG_LEVEL.DEBUG},
  Reports: {name: "Reports", enabled: true, level: LOG_LEVEL.INFO},
  Repository: {name: "Repository", enabled: true, level: LOG_LEVEL.INFO},
  RepositorySQL: {name: "Repository:SQL", enabled: true, level: LOG_LEVEL.INFO},
  Security: {name: "Security", enabled: true, level: LOG_LEVEL.INFO},
  System: {name: "System", enabled: true, level: LOG_LEVEL.INFO},
  TechTransfer: {name: "TechTransfer", enabled: true, level: LOG_LEVEL.INFO},
  Telemetry: {name: "Test", enabled: true, level: LOG_LEVEL.INFO},
  Template: {name: "Template", enabled: true, level: LOG_LEVEL.INFO},
  Test: {name: "Test", enabled: true, level: LOG_LEVEL.INFO},
  Training: {name: "Training", enabled: true, level: LOG_LEVEL.INFO},
  Transactions: {name: "Transactions", enabled: true, level: LOG_LEVEL.INFO},
  Users: {name: "Users", enabled: true, level: LOG_LEVEL.INFO},
  WebSockets: {name: "WebSockets", enabled: true, level: LOG_LEVEL.INFO},
  Library: {name: "Library", enabled: true, level: LOG_LEVEL.INFO},
  Cache: {name: "CACHE", enabled: true, level: LOG_LEVEL.INFO},
};

/**
 * @type {ILogLevels}
 */
LOG_CONFIG.levels = LOG_LEVEL;

/**
 * @type {ILogGroups}
 */
LOG_CONFIG.groups = LOG_GROUP;

const RAW_TYPES = ["string", "number", "function", "boolean", "bigint", "symbol"];

let colorize = (text, ignored) => text;
let resetColor = "";
const IS_COLORIZED = IS_BACKEND && IS_LOCAL;

if (IS_COLORIZED) {
  const colors = LOG_CONFIG.randomColors;
  const componentNameColors = new Map();
  colorize = (text, color) => {
    if (!color) {
      if (componentNameColors.has(text)) {
        color = componentNameColors.get(text);
      } else {
        // makes sure the same component names (log groups) will always have the same random color
        const randomColorIndex = (componentNameColors.size % colors.length);
        color = colors[randomColorIndex] || "white";

        // if we get to the max size of the cache, we delete one item before adding a new one
        if (componentNameColors.size > LOG_CONFIG.maxCachedIdentifiers) {
          const firstEntry = componentNameColors.entries().next();
          const [firstKey] = firstEntry.value;

          componentNameColors.delete(firstKey);
        }

        componentNameColors.set(text, color);
      }
    }
    const colorEntry = typeof color === "string" ? LOG_STYLES[color] : color;
    return (colorEntry.open + text + colorEntry.close);
  };
  resetColor = LOG_STYLES.white.open;
}

function formatDate(baseDate) {
  return baseDate.format(LOG_CONFIG.dateFormat);
}

/**
 *
 * @param logLevel {ILogLevel}
 * @param [group] {?ILogGroup | string}
 * @param [typeOrCategory {string}
 * @param [showTimestamp] {boolean}
 * @returns {string}
 */
function formatLogEntry(logLevel, group = null, typeOrCategory = null, showTimestamp = false) {
  let groupEntry = getLogGroupEntry(group);
  let groupName = group;

  if (groupEntry) {
    groupName = groupEntry.name;
  }
  const logLevelForDisplay = logLevel.name.toUpperCase();
  const separator = `${colorize(LOG_CONFIG.separator, LOG_CONFIG.separatorColor)}${resetColor}`;

  const levelColor = logLevel.color || LOG_CONFIG.neutralColor;

  // If running locally, uses the local date. If on a server, forces UTC.
  const baseDate = IS_LOCAL ? moment() : moment.utc();

  // displays the timestamp only when running in the backend (client already has it)
  let timestamp = showTimestamp
    ? `[${colorize(formatDate(baseDate), LOG_CONFIG.dateColor)}] `
    : "";

  const levelDisplay = `<${colorize(logLevelForDisplay, levelColor)}>`;

  const header = `${resetColor}${timestamp}${levelDisplay}`;
  let content = header;

  if (groupName) {
    const typeOrCategoryForDisplay = typeOrCategory
      ? `${colorize(groupName)}::${colorize(typeOrCategory)}`
      : `${colorize(groupName)}`;

    content = `${header} [${typeOrCategoryForDisplay}]`;
  }
  return `${content} ${separator}`;
}

/**
 * @param group {?ILogGroup | string | null}
 * @returns {ILogGroup | null}
 */
function getLogGroupEntry(group) {
  let groupEntry = null;

  if (group !== null) {
    if (typeof group === "object") {
      groupEntry = group;
    } else {
      groupEntry = LOG_CONFIG.groups[group];
    }
  }
  return groupEntry;
}

/**
 * Evaluates a complex condition to determine whether the log is enabled or not.
 * @param {ILogLevel} logLevel The log level of this logger
 * @param {(function():boolean)|boolean} [condition] A function or boolean to determine whether to log or not
 * @param {?ILogGroup|string} [group] The log group to load the settings from and to use in the log message (or a string with an ad-hoc group name).
 * @returns {boolean} A boolean indicating whether or not the logger is enabled.
 */
function matchCondition(logLevel, condition = null, group = null) {
  let result = true;

  let groupEntry = getLogGroupEntry(group);
  if (groupEntry) {
    result = result && groupEntry && groupEntry.enabled && (logLevel.value >= groupEntry.level.value);
  } else {
    result = result && (logLevel.value >= LOG_CONFIG.level.value);
  }

  if (condition !== null) {
    result = result && (typeof condition === "function" ? condition() : condition);
  }

  return result;
}

/**
 * Creates a function bound to {@link console.log} with parameters that log messages according to the parameters
 * @param level {ILogLevel} The level of the log message
 * @param [condition] {boolean|function():boolean} A boolean or function that determines whether or not to log
 * @param [group] {ILogGroup|string} The logger group or ad-hoc group name used to load settings (if available) and display log messages
 * @param [typeOrCategory] {string} The name of the type or category inside the log group.
 * @returns {LoggerFunction} A function bound to {@link console.log} that logs the message.
 */
function bindLogger(level, condition = null, group = null, typeOrCategory = null) {
  // displays the timestamp only when running locally in the backend (client already has it)
  let showTimestamp = IS_BACKEND && IS_LOCAL;

  let logFunction;

  switch (level.value) {
    case LOG_LEVEL.WARN.value:
      logFunction = console.warn;
      break;
    case LOG_LEVEL.ERROR.value:
      logFunction = console.error;
      break;
    default:
      logFunction = console.log;
  }

  let boundFunction;
  boundFunction = (...optionalParams) => logFunction.bind(logFunction)(
    formatLogEntry(level, group, typeOrCategory, showTimestamp), ...getParamsArray(optionalParams)
  );

  // noinspection JSUnusedLocalSymbols
  return matchCondition(level, condition, group)
    ? boundFunction
    // eslint-disable-next-line no-unused-vars
    : (...optionalParams) => {
      // do nothing
    };
}

/**
 * Receives an array of method parameters, then put all its values into an array. If the parameter is a function, invokes it and returns its value.
 * @param params
 * @returns {*[]}
 */
function getParamsArray(params) {
  // not using Params.map so we don't add more stuff to the stack trace.
  let result = [];

  for (let param of params) {
    let mappedParam;

    if (param instanceof LogParam) {
      mappedParam = param.getValue();
    } else if (typeof param === "function") {
      mappedParam = logParam(param).getValue();
    } else if (param && typeof param === "object") {
      mappedParam = param.toString();
      if (mappedParam === "[object Object]") {
        mappedParam = Log.object(mappedParam).getValue();
      }
    } else {
      if (IS_LOCAL && typeof mappedParam === "string" && mappedParam.length > LOG_CONFIG.suspiciousStringThreshold) {
        throw new Error(`You are passing a long string to the logs. It is likely that you are putting the result of some calculation directly into the logs. Please make sure to use Log.json or pass your call into a function, so we ensure the execution of such calculation only happens when this log level is enabled. Was: ${Log.symbol(typeof param)}\n${Log.stackTrace()}`);
      }
      mappedParam = param;
    }
    result.push(mappedParam);
  }
  return result;
}

/**
 * Creates a function that, will simply return a value and, if its toString method is called, it also returns the same value.
 *
 * This is used to make the log formatting functions (such as Log.json) return a lazy function when used as a parameter, or a string if used directly inside other string.
 * @param value {function() : *}
 * @param isObject {boolean} If true, won't attempt to convert the value to string when writing to the log.
 * @returns {LogParam}
 */
function logParam(value, isObject = false) {
  return new LogParam(value, isObject);
}

class LogManager {
  /**
   * Manages logging for a logging output defined by the {@link console.Console}
   * @param consoleInstance {Console}
   */
  constructor(consoleInstance = console) {
    /**
     * The current console instance
     * @type {Console}
     */
    this.console = consoleInstance;
    this.patternsToIgnoreInStackTrace = /^(logParam|LogParam\.|Log\.|LogManager\.)/;
  }

  /**
   * Creates a logger for the specified log group, loading its settings if available.
   * @param group {ILogGroup|string} A {@link ILogGroup} object as exposed on the {@link LOG_GROUP} constant,
   * or a string with the group name for ad-hoc groups.
   * @param [typeOrCategory] {string} The name of the type or category inside that log group
   * @returns {ILogger}
   */
  group(group, typeOrCategory = null) {

    // TODO: implement conditional logging when needed
    const condition = null;

    /**
     * We don't use a class here so that all items are bound to {@link console} methods
     */
    const logBinding = {
      logVerbose: bindLogger(LOG_LEVEL.VERBOSE, condition, group, typeOrCategory),
      isVerbose: matchCondition(LOG_LEVEL.VERBOSE, condition, group),
      logInfo: bindLogger(LOG_LEVEL.INFO, condition, group, typeOrCategory),
      isInfo: matchCondition(LOG_LEVEL.INFO, condition, group),
      logDebug: bindLogger(LOG_LEVEL.DEBUG, condition, group, typeOrCategory),
      isDebug: matchCondition(LOG_LEVEL.DEBUG, condition, group),
      logWarn: bindLogger(LOG_LEVEL.WARN, condition, group, typeOrCategory),
      isWarn: matchCondition(LOG_LEVEL.WARN, condition, group),
      logError: bindLogger(LOG_LEVEL.ERROR, condition, group, typeOrCategory),
      isError: matchCondition(LOG_LEVEL.ERROR, condition, group),
    };

    return {
      /**
       * Indicates whether {@link LOG_LEVEL.ERROR} logs are enabled
       * @type {boolean}
       */
      get isError() {
        return logBinding.isError;
      },
      /**
       * Indicates whether {@link LOG_LEVEL.DEBUG} logs are enabled
       * @type {boolean}
       */
      get isDebug() {
        return logBinding.isDebug;
      },
      /**
       * Indicates whether {@link LOG_LEVEL.INFO} logs are enabled
       * @type {boolean}
       */
      get isInfo() {
        return logBinding.isInfo;
      },
      /**
       * Indicates whether {@link LOG_LEVEL.VERBOSE} logs are enabled
       * @type {boolean}
       */
      get isVerbose() {
        return logBinding.isVerbose;
      },
      /**
       * Logs the result of the specified callback at {@link LOG_LEVEL.VERBOSE} level
       */
      verbose(...params) {
        logBinding.isVerbose && logBinding.logVerbose(...params);
      },
      /**
       * Logs the result of the specified callback at {@link LOG_LEVEL.DEBUG} level
       */
      debug(...params) {
        logBinding.isDebug && logBinding.logDebug(...params);
      },

      /**
       * Logs the result of the specified callback at {@link LOG_LEVEL.INFO} level
       */
      info(...params) {
        logBinding.isInfo && logBinding.logInfo(...params);
      },
      /**
       * Logs the result of the specified callback at {@link LOG_LEVEL.WARN} level
       */
      warn(...params) {
        logBinding.isWarn && logBinding.logWarn(...params);
      },
      /**
       * Logs the result of the specified callback at {@link LOG_LEVEL.ERROR} level
       */
      error(...params) {
        logBinding.isError && logBinding.logError(...params);
      },
      /**
       * Logs the result of the specified callback at {@link LOG_LEVEL.INFO} level ONLY WHEN RUNNING IN LOCALHOST
       */
      local(...params) {
        IS_LOCAL && logBinding.logInfo(...params);
      },
      /**
       * Logs as {@link LOG_LEVEL.INFO} a jumbotron-like message.
       * @param title {string} the title of the jumbotron window
       * @param params
       */
      printJumbotron(title, ...params) {
        logBinding.logInfo(
          Log.frame(LOG_LEVEL.INFO, params.join(" "), true, `${title}\n`, true),
        );
      },
      /**
       * Returns a function that will log the specified message and return the object passed as its first parameter.
       * This is useful when you need to log the result of a promise while debugging.
       * @param message
       * @return {function(*=): *}
       */
      verboseReturn(message = ">> Result: ") {
        return (result) => {
          this.verbose(message, Log.object(result));
          return result;
        };
      },

      /**
       * Returns a function that will log the specified message and return the object passed as its first parameter.
       * This is useful when you need to log the result of a promise while debugging.
       * @param message
       * @return {function(*=): *}
       */
      debugReturn(message = ">> Result: ") {
        return (result) => {
          this.debug(message, Log.object(result));
          return result;
        };
      },

      /**
       * Returns a function that will log the specified message and return the object passed as its first parameter.
       * This is useful when you need to log the result of a promise while debugging.
       * @param message
       * @return {function(*=): *}
       */
      infoReturn(message = ">> Result: ") {
        return (result) => {
          this.info(message, Log.object(result));
          return result;
        };
      },

      /**
       * Returns a function that will log the specified message and return the object passed as its first parameter.
       * This is useful when you need to log the result of a promise while debugging.
       * @param message
       * @return {function(*=): *}
       */
      warnReturn(message = ">> Result: ") {
        return (result) => {
          this.warn(message, Log.object(result));
          return result;
        };
      },

      /**
       * Returns a function that will log the specified message and return the object passed as its first parameter.
       * This is useful when you need to log the result of a promise while debugging.
       * @param message
       * @return {function(*=): *}
       */
      errorReturn(message = ">> Result: ") {
        return (result) => {
          this.error(message, Log.object(result));
          return result;
        };
      },
    };
  }

  symbol(value, useColor = true) {
    return logParam(() => {
      if (useColor) {
        return `${resetColor}${colorize(value)}${resetColor}`;
      } else {
        return value;
      }
    });
  }

  id(value, type = null, useColor = true) {
    return logParam(() => {
      if (value && typeof value === "object") {
        if (value.name && value.id) {
          if (value.typeCode) {
            // not using the method from CommonUtils because this should not depend on anything
            // also, it doesn't matter, since it's not visible to the client
            value = `${value.typeCode}-${value.id} - ${value.name}`;
          } else {
            value = `${value.id} - ${value.name}`;
          }
        }
        if (value.name || value.id) {
          value = value.name || value.id || value;
        }

        if (!type) {
          type = value.typeCode || value.modelName || null;
        }
      }
      return `[${type ? this.symbol(type, useColor) + ": " : ""}${this.symbol(value, useColor)}]`;
    });
  }

  /**
   * Renders a list with the properties of an object and their values
   * @param object {*} The object that will have their properties dumped
   * @param levels {number} The number of levels to dump (if more than one, it will recurse this for every property.
   * @param currentLevel {number} The current indentation level (important when calling recursively).
   * @return {LogParam}
   */
  props(object, levels = 1, currentLevel = 0) {
    return logParam(() => {
      const result = [];

      if (currentLevel < levels) {
        for (let [key, value] of Object.entries(object)) {
          const indent = "".padStart(currentLevel, "  ");
          result.push(`${indent} - ${key}: ${this.props(value, levels, currentLevel + 1)}`);
        }
        return result.join("\n");
      } else {
        return this.symbol(object);
      }
    });
  }

  /**
   * Paints the specified log text with the specified color.
   * @param text
   * @param color
   * @returns {*}
   */
  colorize(text, color) {
    // the public method just calls the local function
    return colorize(text, color);
  }

  json(value, type = undefined, maxLength = LOG_CONFIG.maxJSONLength, forceFormat = false) {
    return logParam(() => {
      if (RAW_TYPES.includes(typeof value)) {
        type = typeof value;
        return this.id(value, type).toString();
      }
      // if there is no type, uses the value as the type (this will be resolved by the ID function)
      if (!type) {
        type = value;
      }

      // gets the type formatted as an ID (if the value is an object, ID handles it)
      type = this.id(type);

      const replacer = (replacerKey, replacerValue) => {
        if (replacerValue && typeof replacerValue === "object" && replacerValue.stack) {
          replacerValue = this.error(replacerValue).toString();
        }
        // See https://stackoverflow.com/questions/31190885/json-stringify-a-set
        if (replacerValue && typeof replacerValue === "object" && (replacerValue instanceof Set || replacerValue instanceof Map)) {
          replacerValue = Array.from(replacerValue);
        }

        return replacerValue;
      };
      let content = "";
      try {
        if (LOG_CONFIG.formatJSON || forceFormat) {
          content += JSON.stringify(value, replacer, 2);
        } else {
          content += JSON.stringify(value, replacer);
        }
      } catch (error) {
        if (Array.isArray(value)) {
          content += `[\n  ${value.join(",\n  ")}\n]`;
        }
        if (typeof value === "object") {
          content += `{\n  ${Object.entries(value).map(([key, innerValue]) => `${key}: ${innerValue}`).join(",\n  ")}\n}`;
        } else {
          content += `value`;
        }
      }

      // if running in backend and local, does special formatting. Otherwise, returns the full JSON (or fallback string)
      if (content && content.length > maxLength) {
        content = `${content.substring(0, maxLength)}\n  ${this.symbol("...")}\n (${colorize("truncated", LOG_LEVEL.ERROR.color)})`;
      }
      if ((IS_BACKEND && IS_LOCAL) || forceFormat) {
        return this.frame(null, content, true, type).toString();
      } else {
        return content;
      }
    });
  }

  separator() {
    return logParam(() => {
      return `${colorize(LOG_CONFIG.separator, LOG_CONFIG.separatorColor)}`;
    });
  }

  model(model, type) {
    return logParam(() => {
      // if this is a sequelize model, gets the plain object
      if (model && Array.isArray(model)) {
        model = model.map(item => this.getPlainModel(item));
      } else {
        model = this.getPlainModel(model);
      }

      // the type is a MetaModel instance
      if (this.isMetaModel(type)) {
        type = type.name;
      } else {
        type = type || this.getModelType(model);
      }
      type = type || this.getModelType(model);
      return this.json(model, type).toString();
    });
  }

  object(...objects) {
    const getter = (
      IS_BACKEND
        ? () => this.json(objects.length > 1 ? objects : objects[0]).toString()
        : () => objects.length > 1 ? objects : objects[0]
    );

    return logParam(getter, true);
  }

  error(error, expected = false) {
    return logParam(() => {
      const level = LOG_LEVEL.ERROR;

      const errorContents = error && (error.message || error.code || error.stack)
        ? `${colorize(error.message, level.color)}\n`
        + `${this.separator()} ${this.symbol("Name")}: ${error.name}\n`
        + (error.code ? `\n${this.separator()} ${this.symbol("Code")}: ${error.code}\n` : "")
        + (error.uuid ? `\n${this.separator()} ${this.symbol("UUID")}: ${error.uuid}\n` : "")
        + (error.category ? `\n${this.separator()} ${this.symbol("Category")}: ${error.category}\n` : "")
        + (error.doNotSendStackTrace ? `\n${this.separator()} ${this.symbol("Do Not Send Stack Trace")}: ${error.doNotSendStackTrace}\n` : "")
        + (error.isValidation ? `\n${this.separator()} ${this.symbol("Is Validation")}: ${error.isValidation}\n` : "")
        + (error.model ? `\n${this.separator()} ${this.symbol("Model")}: ${error.model}\n` : "")
        + (error.sql ? `\n${this.separator()} ${this.symbol("SQL")}: ${error.sql}\n` : "")
        + `${this.separator()} ${this.symbol("Stack Trace")}:\n`
        + `${colorize(error.stack, LOG_CONFIG.errorStackTraceColor)}\n`
        : this.json(error, (error && error.name) || "Error").toString();

      return this.frame(
        expected ? LOG_LEVEL.INFO : LOG_LEVEL.ERROR,
        `\n${colorize(`${level.name.toUpperCase()}`, level.color)} ${this.separator()} ${errorContents}`
      ).toString();
    });
  }

  /**
   * Displays the stack trace for the current log invocation
   * @param skipCount {number} The number of stack frames to ignore (starting from the top)
   * @returns {LogParam}
   */
  stackTrace(skipCount = 0) {
    return logParam(() => {
      const stackFrames = this.getStackFrames(skipCount);
      let stackTraceContents = stackFrames.join("\n");
      return `\n${this.separator()} ${this.symbol("Logger Stack Trace")}: \n${colorize(stackTraceContents, LOG_LEVEL.INFO.color)}\n`;
    });
  }

  /**
   * Displays information about the method that called this log function.
   * @param frameIndex {number} The index of the frame to return, counting from the top. The default is the topmost one (0)
   * @returns {LogParam}
   */
  caller(frameIndex = 0) {
    return logParam(() => {
      const stackFrames = this.getStackFrames(frameIndex);
      return this.id((stackFrames[0] || "UNKNOWN").trim(), "Fn").toString();
    });
  }

  /**
   * Displays an output frame "window"
   * @param logLevel {ILogLevel} The level of this log message
   * @param text {*}
   * @param keepIndent {boolean}
   * @param [title] {?string}
   * @param [keepFrameWhenColorIsDisabled] {boolean} If true, it will render the frame even if the colors are disabled.
   */
  frame(logLevel, text, keepIndent = false, title = null, keepFrameWhenColorIsDisabled = false) {
    return logParam(() => {
      if (!logLevel) {
        logLevel = LOG_LEVEL.OUTPUT;
      }
      const color = logLevel.color;

      function colorizeText(text, backgroundColor) {
        if (backgroundColor) {
          return colorize(colorize(text, color), backgroundColor);
        } else {
          return colorize(text, color);
        }
      }

      const lines = (text || "").trim().split("\n");
      if (logLevel) {
        lines.unshift(`${logLevel.icon} ${colorize(logLevel.name.toUpperCase(), LOG_STYLES.bold)}: ${title && typeof title === "string" ? title.trim() : ""}`);
      }

      if (typeof text === "string") {
        let frameChars = "  ";
        if (keepFrameWhenColorIsDisabled && !IS_COLORIZED) {
          frameChars = "██";
        }
        return `${resetColor}\n\n${lines.map(line => `${colorizeText(frameChars, logLevel.frameColor)}  ${colorizeText(keepIndent ? line : line.trim())}`)
          .join("\n")}${resetColor}`;
      } else {
        return colorizeText(text);
      }
    });
  }

  /**
   * Returns an array with the stack frames for the current log call
   * @param skipCount {number} The number of stack frames to ignore (starting from the top)
   * @returns {*}
   * @private
   */
  getStackFrames(skipCount = 0) {
    // hack to get a stack trace for the method that is calling the logger by populating an empty object as if it was an error object
    let stackTrace = {};
    Error.captureStackTrace(stackTrace);
    const text = stackTrace.stack;

    let actualCallerFrameList = [];
    // skips first line, since it contains only "Error:"
    const fullFrameList = text.split("\n").splice(1);

    // Doesn't include the frames inside the LogParam class.
    for (let frame of fullFrameList) {
      const frameValue = frame.trim();
      let frameWords = frameValue.split(" ");

      const isFromLogger = !this.patternsToIgnoreInStackTrace.test(frameWords[1]);

      if (isFromLogger) {
        actualCallerFrameList.push(frameValue);
      }
    }

    // Doesn't include the frames from common_log
    const firstNonCommonLogFrame = actualCallerFrameList.find(frame => !frame.includes("common_log.js"));
    const firstNonCommonLogFrameIndex = actualCallerFrameList.indexOf(firstNonCommonLogFrame);
    if (firstNonCommonLogFrameIndex > 0 && firstNonCommonLogFrameIndex < actualCallerFrameList.length) {
      actualCallerFrameList = actualCallerFrameList.slice(firstNonCommonLogFrameIndex);
    }

    // ensures the frame index is within range
    const firstFrameToInclude = Math.min(Math.max(0, skipCount), actualCallerFrameList.length - 1);
    return actualCallerFrameList.splice(firstFrameToInclude);
  }

  /**
   * @param model
   * @returns {*}
   * @private
   */
  getModelType(model) {
    return model && (model.modelName || model.typeCode);
  }

  /**
   * @param item
   * @returns {*}
   * @private
   */
  getPlainModel(item) {
    return (item && typeof item.get === "function") ? item.get({plain: true}) : item;
  }

  /**
   * @param type
   * @returns {*}
   * @private
   */
  isMetaModel(type) {
    return type && type.name && type.model && type.modelVersion;
  }
}

class LogParam {
  /**
   *
   * @param value {*} The value of the log parameter
   * @param isObject {boolean} If true, it won't attempt to convert the object to string and will just
   * retrieve the full object to be displayed in the log as is.
   */
  constructor(value, isObject = false) {
    this.value = value;
    this.type = "LogParam";
    this.isObject = isObject;
  }

  toString() {
    return this.getValue();
  }

  getValue() {
    let isObject = this.isObject;
    // Log statements shouldn't break the application, but errors must be logged.
    try {
      let result = this.value;

      for (let invokeCount = 0; invokeCount < 100 && result; invokeCount++) {
        if (result instanceof LogParam && result.type === "LogParam") {
          isObject = this.isObject || !!result.isObject;
          result = result.value;
        }

        if (typeof result === "function") {
          result = result();
        } else {
          break;
        }
      }
      return (
        result
          ? (
            isObject ? result : result.toString()
          )
          : result
      );
    } catch (e) {
      console.warn("Error converting log parameter to string. Returning raw value", e);
      return this.value;
    }
  }
}

/**
 * The default instance, that uses the default {@link console} object.
 * @type {LogManager}
 */
const Log = new LogManager(console);

module.exports = {
  /**
   * The default {@link LogManager}, that uses the default {@link console} object.
   * @type {LogManager}
   */
  Log,
  /**
   * Manages log for a {@link Console} output
   */
  LogManager,

  /**
   * Contains the default log groups and their configuration
   */
  LOG_GROUP,

  /**
   * Contains the log levels used in this framework
   */
  LOG_LEVEL,

  /**
   * Exposes the color codes.
   */
  LOG_STYLES,
  /**
   * The configuration for the log service (exposed so it can be overwritten in unit tests)
   */
  LOG_CONFIG,
};
