"use strict";

import * as UIUtils from "../ui_utils";
import * as FailHandlers from "./fail_handlers";
import CommonSecurity from "../../server/common/generic/common_security";
import { Log, LOG_GROUP } from "../../server/common/logger/common_log";
import { Ensure } from "../../server/common/generic/common_ensure";
import { RETRY_WAIT } from "../helpers/retry_wrapper";
import { decompressAndParseJSON } from "../../server/common/generic/common_compression";
import { CommonAggregateError } from "../../server/common/generic/common_aggregate_error";

const Logger = Log.group(LOG_GROUP.Framework, "AjaxWrapper");

/**
 * The functions in this file are responsible for wrapping AJAX calls so that they're encrypted, retry when needed, etc.
 */

/**
 * This method initializes JQuery's AJAX so that it retries all requests that come back with unreadable error messages.
 */
export function init() {
// This is what retries requests where API Gateway returns a 502 Gateway error.
// Ideas for this borrowed from https://stackoverflow.com/a/12446363/491553
  $.ajaxPrefilter(function(opts, originalOpts, jqXHR) {
    if (opts.retryCount === undefined) {
      opts.retryCount = 3;
    }

    // Our own deferred object to handle done/fail callbacks
    let dfd = $.Deferred();

    // If the request works, return normally
    jqXHR.done((result, ignore, jqXHR) => {
      if (!jqXHR) {
        Logger.warn(() => "Received a successful response, but the jqXHR object is null. This is unexpected.", Log.object(result));
        dfd.resolve(result);
        return;
      }

      if (opts.silent) {
        Logger.verbose(() => "Successfully received " + opts.type + " request from " + opts.url + ".");
      } else {
        Logger.info(() => "Successfully received " + opts.type + " request from " + opts.url + ".");
      }
      if (opts.clearUIErrors) {
        Logger.info(() => "Clear UI errors triggered after downloading URL " + opts.url);
        UIUtils.clearError();
      }

      let decompressResult = jqXHR.getResponseHeader("X-Qbd-Report-Response-Compressed");
      Logger.verbose(() => "Received response header X-Qbd-Report-Response-Compressed:   " + decompressResult);
      if (decompressResult === "true") {
        const response = decompressAndParseJSON(result.data);
        Logger.debug(() => "Successfully resolved result from " + opts.url + ".", Log.object(response));
        dfd.resolve(response);
      } else {
        Logger.debug(() => "Successfully resolved result from " + opts.url + ".", Log.object(result));
        dfd.resolve(result);
      }
    });

    // If the request fails, retry a few times, yet still resolve
    jqXHR.fail((xhr, textStatus, errorThrown) => {
      // This error should be logged as error in UIUtils. If not, we have it here as warning
      Logger.warn(() => "Caught error: " + JSON.stringify(xhr) + ", textStatus: " + textStatus + ", errorThrown: " + errorThrown);
      if (isAjaxErrorRetryable(xhr, opts)) {
        // API Gateway gave up. Let's retry.
        if (opts.retryCount-- > 0) {
          UIUtils.setHideLoadingOnAjaxStop(false);
          let retryWait = RETRY_WAIT.reverse()[opts.retryCount];
          Logger.info(() => "Retrying after waiting " + retryWait + " ms...");
          UIUtils.showLoadingImage("Cannot reach QbDVision.  Retrying in " + (retryWait / 1000) + " seconds...");
          setTimeout(() => {
            // Retry with a copied originalOpts with retryCount. When retrying we also want to clear the UI error that
            // was displayed from the previous failed attempt.
            let newOpts = $.extend({}, originalOpts, {
              retryCount: opts.retryCount,
              clearUIErrors: true
            });
            $.ajax(newOpts).done(dfd.resolve);
            UIUtils.setHideLoadingOnAjaxStop(true);
          }, retryWait);
        } else {
          // Shows the error in a modal window instead of displaying a broken page
          UIUtils.displayErrorModal(`Cannot reach QbDVision`, "Cannot reach QbDVision.  Please check your internet connection and then try again.", {
            allowHide: false,
            hideBody: true
          });
          UIUtils.hideLoadingImage();
        }
      } else {
        if (originalOpts.error) {
          originalOpts.error(xhr, textStatus, errorThrown);
        } else {
          FailHandlers.defaultFailFunction(xhr, textStatus, errorThrown);
        }
      }
    });

    // Now override the jqXHR's promise functions with our deferred
    return dfd.promise(jqXHR);
  });
}

/**
 * Determines if the error from the ajax call is retryable
 * @param xhr The xhr object
 * @param opts The options passed in the ajax request
 * @returns {*|boolean}
 */
function isAjaxErrorRetryable(xhr, opts) {
  const isRetryable = xhr &&
    (xhr.readyState === 0 && xhr.status === 0 && xhr.statusText === "error") ||
    (opts.idempotent && xhr.readyState === 4 && (xhr.status === 502 || xhr.status === 504) && xhr.statusText === "error");

  console.log(`Ajax request failed. Is retryable: ${isRetryable}`, xhr, opts);

  return isRetryable;
}

/**
 * Makes an authenticated ajax GET call to a relative path securely.
 * This method prepends the relative path with UIUtils.API_URL.
 * @param path {string} The relative URL path to make a call to.
 * @param [data] {*} The data in a JSON format to pass in the ajax call.
 * @param [global] {boolean} If true then the loading indicator is displayed, if false it is not.
 * @param [failCallback] {function} A callback function which is invoked instead of the default if the ajax call fails.
 * @param [clearUIErrors] {boolean} If set to true (the default) it will clear any errors on the UI by calling UIUtils.clearError().
 * @param [silent] {boolean} If set to true, does not log anything when sending the request.
 * @returns {*}
 */
export function secureAjaxGET(path, data, global, failCallback, clearUIErrors, silent) {
  return secureAjax("GET", path, data, global, failCallback, true, clearUIErrors, silent);
}

/**
 * Makes an authenticated ajax POST call to a relative path securely.
 * This method prepends the relative path with UIUtils.API_URL.
 * @param path The relative URL path to make a call to.
 * @param data {*} The data in a JSON format to pass in the ajax call.
 * @param [global] {boolean} If true then the loading indicator is displayed, if false it is not.
 * @param [failCallback] {function} A callback function which is invoked instead of the default if the ajax call fails.
 * @param [idempotent] {boolean} Set to true if a request can be retried when it fails.
 * @param [clearUIErrors] {boolean} If set to true (the default) it will clear any errors on the UI by calling UIUtils.clearError().
 * @param [silent] {boolean} If set to true, does not log anything when sending the request.
 * @returns {*}
 */
export function secureAjaxPOST(path, data, global, failCallback, idempotent, clearUIErrors, silent) {
  return secureAjax("POST", path, data, global, failCallback, idempotent, clearUIErrors, silent);
}

/**
 * Makes an authenticated ajax PUT call to a relative path securely.
 * This method prepends the relative path with UIUtils.API_URL.
 * @param path The relative URL path to make a call to.
 * @param data {*} The data in a JSON format to pass in the ajax call.
 * @param [global] {boolean} If true then the loading indicator is displayed, if false it is not.
 * @param [failCallback] {function} A callback function which is invoked instead of the default if the ajax call fails.
 * @param [idempotent] {boolean} Set to true if a request can be retried when it fails.
 * @param [clearUIErrors] {boolean} If set to true (the default) it will clear any errors on the UI by calling UIUtils.clearError().
 * @param [silent] {boolean} If set to true, does not log anything when sending the request.
 * @returns {*}
 */
export function secureAjaxPUT(path, data, global, failCallback, idempotent, clearUIErrors, silent) {
  return secureAjax("PUT", path, data, global, failCallback, idempotent, clearUIErrors, silent);
}

/**
 * Makes an authenticated ajax DELETE call to a relative path securely.
 * This method prepends the relative path with UIUtils.API_URL.
 * @param path The relative URL path to make a call to.
 * @param [data] {*} The data in a JSON format to pass in the ajax call.
 * @param [global] {boolean} If true then the loading indicator is displayed, if false it is not.
 * @param [failCallback] {function} A callback function which is invoked instead of the default if the ajax call fails.
 * @param [idempotent] {boolean} Set to true if a request can be retried when it fails.
 * @param [clearUIErrors] {boolean} If set to true (the default) it will clear any errors on the UI by calling UIUtils.clearError().
 * @param [silent] {boolean} If set to true, does not log anything when sending the request.
 * @returns {*}
 */
export function secureAjaxDELETE(path, data, global, failCallback, idempotent, clearUIErrors, silent) {
  return secureAjax("DELETE", path, data, global, failCallback, idempotent, clearUIErrors, silent);
}

/**
 * Makes an authenticated ajax DELETE call to a relative path securely.
 * This method prepends the relative path with UIUtils.API_URL.
 * @param type The type of the AJAX call such as GET, PUT, POST and DELETE.
 * @param path The relative URL path to make a call to.
 * @param [data] {*} The data in a JSON format to pass in the ajax call.
 * @param [global] {boolean} If true then the loading indicator is displayed, if false it is not.
 * @param [failCallback] {function} A callback function which is invoked instead of the default if the ajax call fails.
 * @param [idempotent] {boolean} Set to true if a request can be retried when it fails.
 * @param [clearUIErrors] {boolean} If set to true (the default) it will clear any errors on the UI by calling UIUtils.clearError().
 * @param [silent] {boolean} If set to true, does not log anything when sending the request.
 * @returns {*}
 */
function secureAjax(type, path, data, global, failCallback, idempotent, clearUIErrors = true, silent = false) {
  global = (typeof global === "undefined") ? true : global;

  const getErrorCallback = (url) => (result, ...rest) => {
    const callbackToInvoke = failCallback ? failCallback : FailHandlers.defaultFailFunction;
    // Adds additional information to the error object so the telemetry can
    // report more meaningful data in case of failure.
    if (result) {
      result.requestParams = {
        type,
        path,
        url,
        data,
        global,
        idempotent,
        transport: "http",
      };
    }
    return callbackToInvoke(result, ...rest);
  };

  let url;
  try {
    url = getURL(path);

    Logger.info(() => "Sending " + type + " request to " + url + "...");

    const headers = {
      "Authorization": UIUtils.getAccessToken(),
    };

    return $.ajax({
      cache: false,
      url: url,
      type: type,
      contentType: type === "GET" ? undefined : "text/plain", // This is to solve QI-4305
      data: type === "GET" ? data : JSON.stringify(data), // JQuery will try to URLEncode the data if we don't convert it to a string first for PUT/POST requests. For GET requests we want JQuery to URL encode the data.
      headers,
      global: global,
      error: getErrorCallback(url),
      idempotent: !!idempotent,
      clearUIErrors: !!clearUIErrors,
      silent,
    });
  } catch (error) {
    getErrorCallback(url || path)(error);
    return null;
  }
}

export function getURL(partialPath) {
  Ensure.that({partialPath}).isNotFalsyNorWhiteSpaceString();

  return UIUtils.API_URL + partialPath;
}

function isErrorExpected(error) {
  return error.isValidation || error.doNotSendStackTrace;
}

function extractInfoFromStandardError(error) {
  let errorInfo;

  if (isErrorExpected(error)) {
    errorInfo = createErrorInfo(error.message, null, error, true);
  } else {
    errorInfo = createErrorInfo(`Unexpected error: ${error.message}`, error.stack, error);
  }
  return errorInfo;
}

export function isAuthenticationError(result) {
  let responseJSON = result.responseJSON ? result.responseJSON : result;

  return responseJSON.statusCode === 401 || result.status === 401 || result.code === 401;
}

function isUserDeletedOrDisabled(message) {
  return message === CommonSecurity.COGNITO_DEVELOPER_ERROR || message === CommonSecurity.USER_DISABLED_MESSAGE;
}

function getAuthenticationErrorReason(error) {
  let reason = "Expired";
  if (error.message === CommonSecurity.USER_DISABLED_MESSAGE) {
    reason = "UserDisabled";
  } else if (error.message === CommonSecurity.COGNITO_DEVELOPER_ERROR) {
    reason = "CognitoDeveloperError";
  }
  return reason;
}

export function redirectToLoginPage(error) {
  let reason = getAuthenticationErrorReason(error);

  const returnToURLParam = window.location.pathname === "/index.html" ? "" :  // Don't return back to the login page
    "&returnTo=" + encodeURIComponent(UIUtils.getSecuredURL(window.location.href, {
      enforceHostWithinQbDVisionDomain: true
    }));
  window.location.href = UIUtils.getSecuredURL("/index.html?reason=" + reason + returnToURLParam);
}

function handleUnauthorizedResponse(result) {
  Logger.info(() => "Checking Response", Log.object(result));
  let error = result?.responseJSON ? result.responseJSON : result;

  if (typeof (error) === "undefined") {
    return false;
  }

  // Handling some cases in which the response is a string containing an error message
  if (typeof error === "string") {
    const isDeletedOrDisabled = isUserDeletedOrDisabled(error);

    error = {
      message: error,
      code: isDeletedOrDisabled ? 401 : 400,
    };
  } else {
    if (!error?.message) {
      return false;
    }
  }

  if (isAuthenticationError(result)) {
    redirectToLoginPage(error);
    return true;
  } else {
    return false;
  }
}

function createErrorInfo(messageText, detailsText = null, error = null, expected = false) {
  error = error || new CommonAggregateError({message: messageText, stack: detailsText});

  return {
    errorText: messageText,
    detailText: detailsText,
    error,
    expected,
    alreadyLogged: error?.alreadyLogged,
    category: error?.category,
  };
}

/*TO-DO. This implies a bad design. The extractErrorDetails function should not be doing any redirects. Those seem to
  be completely different things.
 */
export function extractErrorDetails(result) {
  /* This will redirect the user if unauthorized and a proper message will be displayed, so we are not returning any
     other error info object to avoid error race conditions on the UI.
   */
  if (handleUnauthorizedResponse(result)) {
    return null;
  }

  let errorInfo = null;

  let {
    responseJSON,
    responseText,
    requestParams,
    clientAction,
    reactComponent,
  } = result;

  // depending on the source of the error, the status code might come with different names
  let statusCode = UIUtils.isNumber(result.statusCode)
    ? UIUtils.parseInt(result.statusCode)
    : (
      UIUtils.isNumber(result.status)
        ? UIUtils.parseInt(result.status)
        : (
          UIUtils.isNumber(result.code)
            ? UIUtils.parseInt(result.code)
            : undefined
        )
    );

  if (typeof responseJSON !== "undefined" && responseJSON) {
    if (typeof responseJSON === "string") {
      try {
        responseJSON = JSON.parse(responseJSON);
      } catch (e) {
        Logger.warn("Response is not a valid JSON:", Log.object(responseJSON));
      }
      errorInfo = createErrorInfo(responseJSON);
    } else if (responseJSON.message) {
      errorInfo = extractInfoFromStandardError(responseJSON);
    } else if (responseJSON.error && responseJSON.error.message) {
      errorInfo = extractInfoFromStandardError(responseJSON.error);
    }
  }

  if (!errorInfo && result.message) {
    errorInfo = extractInfoFromStandardError(result);
  }

  if (!errorInfo && responseText) {
    errorInfo = createErrorInfo(responseText);
  }

  if (!errorInfo && typeof result === "string") {
    errorInfo = createErrorInfo(result);
  }

  if (!errorInfo) {
    errorInfo = createErrorInfo("Unexpected failure occurred.  Please contact support.", JSON.stringify(result, null, 2));
  }

  errorInfo.statusCode = statusCode;
  errorInfo.requestParams = requestParams;
  errorInfo.clientAction = clientAction;
  errorInfo.reactComponent = reactComponent;
  return errorInfo;
}


