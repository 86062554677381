"use strict";
// The functions in this file are responsible for producing and checking URLs.

const CommonUtils = require("./common_utils");

module.exports.DEFAULT_RETURN_TO_URL = "/homePage/dashboard.html";

// Loading the URL object is a bit hairy to do because it's different in node than in a browser
let URL;
// Detect if we're in node (https://stackoverflow.com/a/35813135/491553)
if ((typeof process !== "undefined") && process.release && (process.release.name === "node")) {
  URL = require("url").URL;
} else {
  URL = window.URL;
}

//https://www.w3.org/wiki/UriSchemes
const ACCEPTED_URL_PROTOCOLS = ["http", "https", "ftp", "ftps", "sftp", "ssh", "tcp", "ip", "smtp", "mailto", "telnet"];
module.exports.ACCEPTED_URL_PROTOCOLS = ACCEPTED_URL_PROTOCOLS;

module.exports.cleanUpURL = function(url) {

  let cleanURL = url;
  if (url && url.length > 0 && url.indexOf(":") < 0) {
    cleanURL = "https://" + cleanURL;
  }
  return cleanURL;
};

module.exports.logoutExternalUser = function() {
  let externalProviderLogoutURL = `https://qbdvision-${CommonUtils.SUBDOMAIN}.auth.${CommonUtils.AWS_REGION}.amazoncognito.com`;
  externalProviderLogoutURL += `/logout?response_type=code&`;
  externalProviderLogoutURL += `client_id=${CommonUtils.COGNITO_CLIENT_ID}&`;
  externalProviderLogoutURL += `logout_uri=${CommonUtils.FRONT_END_URL}/`;
  window.location.href = exports.getSecuredURL(externalProviderLogoutURL);
};

/**
 * Checks if the provided URL is a valid URL using given input protocols.
 * @param url The url to verify
 * @param parameters it can include multiple properties like
 * 1- urlIsCleanedUp True if the url has already been cleaned up, false otherwise. If the url is not a valid url and
 * has not yet been cleaned up, this method will try to clean it up and parse it again, before responding false.
 * 2- acceptedURLProtocols if there is a more strict list than the controlMethod one
 * 3- enforceHostWithinQbDVisionDomain check if the URL is within QbDVision domain
 */
module.exports.isValidURL = function(url, parameters) {

  const urlIsCleanedUp = parameters && parameters.urlIsCleanedUp;
  const acceptedURLProtocols = (parameters && parameters.acceptedURLProtocols) || ACCEPTED_URL_PROTOCOLS;
  const enforceHostWithinQbDVisionDomain = parameters && parameters.enforceHostWithinQbDVisionDomain;

  let protocolNotFound = acceptedURLProtocols.find(value => !ACCEPTED_URL_PROTOCOLS.includes(value));
  if (protocolNotFound) {
    throw new TypeError(`Protocol (${protocolNotFound}) is not supported.`);
  }

  if (url === "" || url === "/") {
    return true;
  }

  try {
    const baseURL = parameters.baseURL || undefined;

    let parsedUrl = new URL(url, baseURL);
    let urlProtocol = parsedUrl.protocol ? parsedUrl.protocol.toLowerCase() : null;
    urlProtocol = urlProtocol && urlProtocol.length > 0 ? urlProtocol.slice(0, -1).toLowerCase() : urlProtocol; // Converts "HTTPS:" to "https"

    let isValidURL = !!(urlProtocol && acceptedURLProtocols.includes(urlProtocol));
    isValidURL = isValidURL && (enforceHostWithinQbDVisionDomain ? parsedUrl.hostname === CommonUtils.FRONT_END_HOST : true);

    return isValidURL;
  } catch (err) {
    if (urlIsCleanedUp) {
      console.warn("Caught while validating " + url + ":", err);
      parameters.error = err;
      return false;
    } else {
      return exports.isValidURL(exports.cleanUpURL(url, parameters), {
        urlIsCleanedUp: true,
        enforceHostWithinQbDVisionDomain
      });
    }
  }
};

module.exports.getSecuredURL = function(url, parameters) {
  const defaultReturnToURL = (parameters && parameters.defaultReturnToURL) || exports.DEFAULT_RETURN_TO_URL;
  const enforceHostWithinQbDVisionDomain = parameters && parameters.enforceHostWithinQbDVisionDomain;
  const baseURL = parameters && parameters.baseURL;
  const throwOnError = parameters && parameters.throwOnError;

  const urlValidationParams = {
    acceptedURLProtocols: ["http", "https"],
    enforceHostWithinQbDVisionDomain,
    baseURL,
  };
  const validURL = exports.isValidURL(url, urlValidationParams);
  if (validURL) {
    // After the Chrome 77 scandal of Sep 2019, DOMPurify now always returns a String and never a TrustedHTML value.
    let returnURL = window.DOMPurify.sanitize(url);
    return returnURL.replace(/&amp;/g, "&"); // DOMPurify now escapes ampersands but we don't want that.
  } else if (throwOnError) {
    const validationError = urlValidationParams.error || "";
    throw new Error("Invalid URL: " + url + "\n" + validationError);
  }
  return defaultReturnToURL;
};

/**
 * This constructs the URL for accessing the Import results page and reviewing the imported records
 * @param projectId The project Id
 * @param modelName The model name
 * @param dependency The dependency name
 * @param dataSectionName The data section name (Process Capability or "")
 * @param importId The import record id
 * @returns {string}
 */
module.exports.getImportResultsReviewURL = function(projectId, modelName, dependency, dataSectionName, importId) {
  let resultLink = `/import/importResultReview.html?importId=${importId}`;
  resultLink += projectId ? `&projectId=${projectId}` : "";
  resultLink += modelName ? `&modelName=${CommonUtils.stripAllWhitespaces(modelName)}` : "";
  resultLink += dependency ? `&dependency=${dependency}` : "";
  resultLink += dataSectionName ? `&dataSectionName=${dataSectionName}` : "";
  return resultLink;
};

