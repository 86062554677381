"use strict";

let defaultInstance;

class JSONConverter {

  static default() {
    if (!defaultInstance) {
      defaultInstance = new JSONConverter();
    }
    return defaultInstance;
  }

  getJsonFieldsFromOptions(options) {
    let {
      jsonArrayFields,
      jsonObjectFields,
    } = options || {};
    jsonArrayFields = jsonArrayFields || [];
    jsonObjectFields = jsonObjectFields || [];
    return {jsonArrayFields, jsonObjectFields};
  }

  serializeJSONArrayField(value, {nullable = false} = {nullable: false}) {
    return value ? this.serializeValue(value) : (nullable ? null : "[]");
  }

  fixError(error) {
    return this.parseValue(this.serializeError(error));
  }

  serializeError(error) {
    let result;
    if (typeof error === "object") {
      let replacer = null;
      if (!error.isPlain) {
        error.isPlain = true;
        // This is a weird tweak, but it fixes a serialization error that happens in some error objects
        // see https://stackoverflow.com/questions/18391212/is-it-not-possible-to-stringify-an-error-using-json-stringify .
        // Also, it may help make the {isTrusted: true} error rarer.
        // https://stackoverflow.com/questions/44815172/log-shows-error-object-istrustedtrue-instead-of-actual-error-data
        const uniquePropertyNames = new Set([
          ...Object.getOwnPropertyNames(Error.prototype),
          ...Object.getOwnPropertyNames(error),
          "message",
          "type",
          "name",
          "code",
          "category",
          "includesHTML",
          "uuid",
          "stack",
        ]);
        replacer = [...uniquePropertyNames];
      }
      result = JSON.stringify(error, replacer);
    } else {
      result = error;
    }
    return result;
  }

  /**
   * Serializes a value to JSON
   * @param value {*} The value to be serialized
   * @param [pretty] {boolean} If true, formats the output.
   * @returns {string}
   */
  serializeValue(value, pretty = false) {
    if (typeof value === "string") {
      return value;
    } else {
      const spaces = pretty ? 2 : 0;

      if (value && typeof value === "object" && value.stack) {
        value = this.fixError(value);
      }

      const replacer = (replacerKey, replacerValue) => {
        if (replacerValue && typeof replacerValue === "object" && replacerValue.stack) {
          replacerValue = this.fixError(replacerValue);
        }
        // See https://stackoverflow.com/questions/31190885/json-stringify-a-set
        if (replacerValue && typeof replacerValue === "object" && (replacerValue instanceof Set || replacerValue instanceof Map)) {
          replacerValue = Array.from(replacerValue);
        }

        return replacerValue;
      };

      if (value && (value instanceof Map || value instanceof Set)) {
        return JSON.stringify([...value], replacer, spaces);
      } else if (value && value[Symbol.iterator] && typeof value[Symbol.iterator] === "function") {
        return JSON.stringify([...value], replacer, spaces);
      } else {
        return JSON.stringify(value, replacer, spaces);
      }
    }
  }

  serializeJSONObjectField(value, {nullable = false} = {nullable: false}) {
    return value ? this.serializeValue(value) : (nullable ? null : "{}");
  }

  parseJSONArrayField(value, {nullable = false} = {nullable: false}) {
    return value ? this.parseValue(value) : (nullable ? null : []);
  }

  parseValue(value) {
    return typeof value === "string" ? JSON.parse(value) : value;
  }

  parseJSONObjectField(value, {nullable = false} = {nullable: false}) {
    return value ? this.parseValue(value) : (nullable ? null : {});
  }

  parseJSONFields(entity, options) {
    let {jsonArrayFields, jsonObjectFields} = this.getJsonFieldsFromOptions(options);

    if (entity) {
      for (let arrayField of jsonArrayFields) {
        if (entity[arrayField] && (!entity[arrayField] || typeof entity[arrayField] === "string")) {
          entity[arrayField] = this.parseJSONArrayField(entity[arrayField], options);
        }
      }
      for (let jsonField of jsonObjectFields) {
        if (entity[jsonField] && (!entity[jsonField] || typeof entity[jsonField] === "string")) {
          entity[jsonField] = this.parseJSONObjectField(entity[jsonField], options);
        }
      }
    }
    return entity;
  }

  serializeJSONFields(entity, options) {
    let {jsonArrayFields, jsonObjectFields} = this.getJsonFieldsFromOptions(options);

    if (entity) {
      for (let arrayField of jsonArrayFields) {
        if (Object.prototype.hasOwnProperty.call(entity, arrayField) && (!entity[arrayField] || typeof entity[arrayField] !== "string")) {
          entity[arrayField] = this.serializeJSONArrayField(entity[arrayField], options);
        }
      }
      for (let jsonField of jsonObjectFields) {
        if (Object.prototype.hasOwnProperty.call(entity, jsonField) && (!entity[jsonField] || typeof entity[jsonField] !== "string")) {
          entity[jsonField] = this.serializeJSONObjectField(entity[jsonField], options);
        }
      }
    }
    return entity;
  }

  /**
   * Merges two arrays, eliminating duplicates
   * @param first {[]}
   * @param second {[]}
   * @return {number[]}
   */
  mergeJSONArrays(first, second) {
    if (typeof first === "string") {
      first = this.parseValue(first);
    }
    if (typeof second === "string") {
      second = this.parseValue(second);
    }

    const newSet = new Set([...first, ...second]);
    return [...(newSet.values())];
  }
}

module.exports = {
  JSONConverter,
};
