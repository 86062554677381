"use strict";

const {UtilityExtension} = require("./common_utility_extension");
const {MODEL_DECLARATIONS} = require("./common_models");

let modelFinderInstance = null;

/**
 * This class is responsible for finding a {@link IModelDeclaration} given some input, such as
 * a model name, a type code or other similar data.
 */
class ModelFinder extends UtilityExtension {
  /**
   * @param commonUtils {CommonUtils} {@link CommonUtils} passed as a reference, since
   * this class will be used from there and we don't want a circular reference.
   * @private
   */
  constructor(commonUtils) {
    super(commonUtils);
    this.rebuildModelIndexes();
  }

  /**
   * Retrieves the singleton instance of the {@link ModelFinder}.
   * This object retrieves information about a model in the system, such as its model name, type code,
   * editor base URL and other names it is known as.
   * @param commonUtils {import("./common_utils")} {@link CommonUtils} passed as a reference, since
   * this class will be used from there and we don't want a circular reference.
   * @returns {ModelFinder}
   */
  static instance(commonUtils = null) {
    if (!modelFinderInstance) {
      modelFinderInstance = new ModelFinder(commonUtils);
    }
    return modelFinderInstance;
  }

  /**
   * Rebuilds the model declarations index from the given model declarations array.
   * (uses the default system model declarations as the default and allows you to extend if you pass something else)
   * @param modelDeclarations {IModelDeclaration[]}
   */
  rebuildModelIndexes(modelDeclarations = []) {
    const modelDeclarationsArray = [
      ...Object.values(MODEL_DECLARATIONS),
      ...(modelDeclarations || [])
    ];

    const typeCodeMap = modelDeclarationsArray.map(
      model => [this._sanitizeTypeCodeForSearch(model.typeCode), model],
    );

    /**
     * @type {Map<string, IModelDeclaration>}
     */
    this.fromTypeCode = new Map(typeCodeMap);

    const modelMap = modelDeclarationsArray.map(
      model => [this._sanitizeModelNameForSearch(model.modelName), model],
    );

    /**
     * @type {Map<string, IModelDeclaration>}
     */
    this.fromModelName = new Map(modelMap);

    const aliasMap = modelDeclarationsArray
      .reduce((result, model) => [
        ...result,
        ...(this._getModelAliasesAsMapEntries(model)),
      ], []);

    /**
     * @type {Map<string, IModelDeclaration>}
     */
    this.fromAlias = new Map(aliasMap);
  }

  /**
   * Retrieves a {@link IModelDeclaration} that describes the type identified by the specified
   * model name.
   * @param modelName {string} The name of the model to retrieve.
   * @returns {IModelDeclaration}
   */
  findFromModelName(modelName) {
    const sanitizedModelName = this._sanitizeModelNameForSearch(modelName);

    let declaration = this.fromModelName.get(sanitizedModelName);

    if (!declaration) {
      declaration = this.fromAlias.get(sanitizedModelName);
    }

    // noinspection JSValidateTypes
    return declaration;
  }

  /**
   * Retrieves a {@link IModelDeclaration} that describes the type identified by the specified
   * type code.
   * @param typeCode {string} The type code of the model to retrieve.
   * @returns {IModelDeclaration}
   */
  findFromTypeCode(typeCode) {
    const sanitizedModelName = this._sanitizeTypeCodeForSearch(typeCode);

    // noinspection JSValidateTypes
    return this.fromTypeCode.get(sanitizedModelName);
  }

  /**
   * Searches for a type code for a given model name (e.g: "CUR" for a "Curricula")
   *
   * If no type code is found, returns null.
   *
   * @param modelName {string} The model name to retrieve a type code for.
   * @returns {string} The type code for that model name.
   * @throws {Error} if the type code is not registered for the specified model name.
   */
  findTypeCodeForModelName(modelName) {
    const declaration = this.findFromModelName(modelName);
    return declaration ? declaration.typeCode : null;
  }

  /**
   * This gives you the model name, with spaces it in. Call CommonUtils.convertToId on this result to get the name that
   * can be used with Sequelize on the back end (ie. when making server calls).
   *
   * If no model name is found, it returns null.
   *
   * @param typeCode The 2-3 letter code for the type you're looking for
   * @returns {string|null} the model name that can be used for display / for interacting with the back end.
   */
  findModelNameForTypeCode(typeCode) {
    const declaration = this.findFromTypeCode(typeCode);
    return declaration ? declaration.modelName : null;
  }

  /**
   * Receives a non standard name model name (such with wrong type casing, or using a type code instead)
   * and retrieves the correct model name for that model type.
   * @param modelNameOrTypeCode {string} The name or type code for the model name to be normalized.
   * @returns {string|null} The correct model name, or null if not found.
   */
  normalizeModelName(modelNameOrTypeCode) {
    let declaration = this.findFromModelName(modelNameOrTypeCode);
    declaration = declaration || this.findFromTypeCode(modelNameOrTypeCode);
    return declaration ? this.commonUtils.convertToId(declaration.modelName) : null;
  }

  /**
   * Retrieves all aliases for a given model as {@link Map} entries
   * (an array of [{@link string}, {@link IModelDeclaration}] entries).
   * @param model
   * @returns {[string, IModelDeclaration][]}
   * @private
   */
  _getModelAliasesAsMapEntries(model) {
    return (model.aliases || []).map(alias => [this._sanitizeModelNameForSearch(alias), model]);
  }

  /**
   * Makes sure the model name is in the format used to store keys in the type index.
   * In other words, ensures it doesn't have any illegal character and is all lowercase.
   * @param modelName {string} The model name to be sanitized.
   * @returns {string}
   */
  _sanitizeModelNameForSearch(modelName) {
    return this.commonUtils.convertToId(modelName).toLowerCase();
  }

  /**
   * Makes sure the type code is in the format used to store keys in the type index.
   * In other words, ensures it doesn't have any illegal character and is all uppercase.
   * @param typeCode {string} The model name to be sanitized.
   * @returns {string}
   * @private
   */
  _sanitizeTypeCodeForSearch(typeCode) {
    return this.commonUtils.convertToId(typeCode).toUpperCase();
  }
}

module.exports = {
  ModelFinder,
};
