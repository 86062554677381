"use strict";
// The functions in this file are responsible for handling users on both the client and server side

const CommonSecurity = require("../generic/common_security");

/**
 * This will format the a given user in the form "FirstName LastName <emailAddress>"
 *
 * @param user The user for which a formatted email address is returned.
 * @return {string} the user's name and email address in a format so that they can be emailed.
 *
 * @see https://en.wikipedia.org/wiki/Email_address#Local-part
 * @see https://stackoverflow.com/questions/2049502/what-characters-are-allowed-in-an-email-address
 */
module.exports.formatUserToShowEmailAddress = function(user) {
  let fullName = exports.getUserFullName(user);
  fullName = fullName.replace(/[^0-9A-Za-z!#$%&'*+/=?^_`{|}~ ]/g, "");
  return `${fullName} <${user.email}>`;
};

/**
 * This constructs the full name for a user.
 * @param user
 * @returns {*|string}
 */
module.exports.getUserFullName = function(user) {
  if(!user) {
    return "Unknown";
  }

  return user.fullName ? user.fullName : `${user.firstName} ${user.lastName}`;
};

/**
 * This will format the a given user in the form "FirstName LastName (JobTitle)". If the user does not have a job title,
 * their department will be used instead.
 *
 * @param user The user for which to format
 * @return {string} the user's name so they can be recognized as a proposer/approver.
 */
module.exports.formatUserToShowTitle = function(user) {
  if(!user) {
    return "Unknown";
  }
  
  let fullName = exports.getUserFullName(user);
  let titleOrDept = "";
  if (user.title) {
    titleOrDept = " (" + user.title + ")";
  } else if (user.department) {
    titleOrDept = (" (Dept: " + user.department + ")");
  }
  return fullName + titleOrDept;
};

/**
 * Converts an email address into a username or returns the username if no email is provided.
 * The username as well as the email address should be case insensitive. This method converts both to lower case.
 *
 * @param value The username or email address provided by the user
 * @returns {string} The username that should be used with Cognito
 */
module.exports.computeUsername = function(value) {
  return value.includes("@") ? value.replace("@", "__").toLowerCase() : value.toLowerCase();
};


/**
 * This function returns default user license for company license
 * @param license The company license
 * @returns {string} The user license
 */
module.exports.getUserLicense = function(license) {
  switch (license) {
    case CommonSecurity.CompanyLicenseTypes.TRIAL:
    case CommonSecurity.CompanyLicenseTypes.PROFESSIONAL:
    case CommonSecurity.CompanyLicenseTypes.INTERNAL:
    case CommonSecurity.CompanyLicenseTypes.EDUCATIONAL:
      return CommonSecurity.UserLicenseTypes.PROFESSIONAL;
    case CommonSecurity.CompanyLicenseTypes.MIXED:
    case CommonSecurity.CompanyLicenseTypes.ESSENTIAL:
    case CommonSecurity.CompanyLicenseTypes.CANCELLED:
      return CommonSecurity.UserLicenseTypes.ESSENTIAL;
  }
};
