"use strict";

import * as React from "react";
import loadingImage from "../images/loading.gif";

/**
 * This function is responsible for creating a lazy loading div with a given ID.
 * @param {string} id - The ID of the div.
 * @param {function} Render - The React class/function to render the content of the div.
 */
export function LazyDiv({id, className, Render}) {
  return <React.Suspense fallback={
    <div id="loadingDiv" className="loading-div">
      <img src={loadingImage} height="128" width="128" id="loadingImg" alt="Loading..." />
      <div id="loadingText">Loading...</div>
    </div>
  }
  >
    <div id={id} className={className}><Render /></div>
  </React.Suspense>;
}
