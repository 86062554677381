"use strict";

import React from "react";

export default function ErrorBar(props) {
  return (
    <div className={"alert alert-danger d-none " + (props.className || "error-bar")}
         role="alert"
         id={props.id || "alertDiv"} />
  );
}
