import React from "react";
import * as Sentry from "@sentry/react";
import * as SentryUtils from "../../server/common/generic/common_sentry_utils";

/**
 * Wrap a React class with a Sentry profiler component
 * @param ReactClazz
 * @returns {*|React.ForwardRefExoticComponent<React.PropsWithoutRef<{}> & React.RefAttributes<unknown>>}
 */
export function wrap(ReactClazz) {
  let isEnabled = SentryUtils.isEnabled;
  if (isEnabled) {
    return withProfiler(ReactClazz, {
      name: ReactClazz.name
    });
  } else {
    return ReactClazz;
  }
}

/**
 * Wrap a React Component with a Sentry profiler component
 * @param component
 * @param componentName
 * @returns {*|React.ForwardRefExoticComponent<React.PropsWithoutRef<{}> & React.RefAttributes<unknown>>}
 */
export function wrapComponent(component, componentName = "") {
  let isEnabled = SentryUtils.isEnabled;
  if (isEnabled) {
    const withWrapper = (WrappedComponent) => React.forwardRef((props, ref) => (
      <WrappedComponent ref={ref} {...props} />
    ));

    return withProfiler(withWrapper(component), {
      name: componentName
    });
  } else {
    return component;
  }
}

/**
 * Wrap React component with Sentry profiler component
 * @param WrappedComponent
 * @param options
 * @returns {React.ForwardRefExoticComponent<React.PropsWithoutRef<{}> & React.RefAttributes<unknown>>}
 */
function withProfiler(WrappedComponent, options) {
  const componentDisplayName = (options && options.name) || WrappedComponent.displayName || WrappedComponent.name || "unknown";
  const Wrapped = React.forwardRef((props, ref) => (
    <Sentry.Profiler {...options} name={componentDisplayName} updateProps={props}>
      <WrappedComponent ref={ref} {...props} />
    </Sentry.Profiler>
  ));

  Wrapped.displayName = `profiler(${componentDisplayName})`;
  // hoistNonReactStatics(Wrapped, WrappedComponent);
  return Wrapped;
}