"use strict";

import Cookies from "js-cookie";
import moment from "moment-timezone";
import * as CommonUtils from "../../../server/common/generic/common_utils";

/**
 * @implements {SpanProcessor}
 */
export class QbDVisionClientSpanProcessor {
  /**
   * @param innerProcessor {SpanProcessor}
   * @param telemetry {Telemetry}
   */
  constructor(innerProcessor, telemetry) {
    /**
     * @type {SpanProcessor}
     */
    this.innerProcessor = innerProcessor;

    /**
     * @type {Telemetry}
     */
    this.telemetry = telemetry;
  }

  onStart(span, parentContext) {
    span.setAttribute("qbd.app.environment", CommonUtils.getDeployEnvironment());
    span.setAttribute("qbd.app.url", window.location.href);
    span.setAttribute("qbd.app.version", CommonUtils.getSoftwareVersion());
    span.setAttribute("qbd.user.company", Cookies.get("COMPANY"));
    span.setAttribute("qbd.user.email", Cookies.get("EMAIL"));
    span.setAttribute("qbd.user.fullName", Cookies.get("USER_FULL_NAME"));
    span.setAttribute("qbd.user.id", Cookies.get("USER_ID"));
    span.setAttribute("qbd.user.name", Cookies.get("USER_NAME"));
    span.setAttribute("qbd.system.browser.userAgent", navigator.userAgent);
    span.setAttribute("qbd.system.browser.language", navigator.language);
    span.setAttribute("qbd.system.browser.languages", navigator.languages.join(", "));
    span.setAttribute("qbd.system.date.local", new Date().toString());
    span.setAttribute("qbd.system.date.timezone", moment.tz.guess());
    span.setAttribute("qbd.system.date.utc", new Date().toUTCString());

    this.innerProcessor.onStart(span, parentContext);
  }

  forceFlush() {
    return this.innerProcessor.forceFlush();
  }

  onEnd(span) {
    this.innerProcessor.onEnd(span);
  }

  shutdown() {
    return this.innerProcessor.shutdown().finally(() => this.telemetry.rootSpan.end());
  }
}
