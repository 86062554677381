"use strict";
// The functions in this file are responsible for ensuring we detect the user's language and show the appropriate text for them.

import i18n from "i18next";
import XHR from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

// This dummy function for constants so i18next-extract can extract them
export const t = (text) => text;

i18n
  // Load the translation from S3
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(XHR)
  // Detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // Pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: "en",
    debug: false, // Leave this off because this flag makes the chrome log so noisy that it is hard to debug flaky tests.

    keySeparator: null,
    nsSeparator: null,
    load: "languageOnly",

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },

    backend: {
      loadPath: "/i18n/locales/{{lng}}/{{ns}}.json",
    },

    detection: {
      // order and from where user language should be detected
      order: ["cookie", "navigator"],

      // keys or params to lookup language from
      lookupCookie: "language",

      // only detect languages that are in the whitelist
      checkWhitelist: true
    },

    // have a common namespace used around the full app
    ns: ["base_page", "constants"],
    fallbackNS: ["base_page", "constants"]
  });

export default i18n;
