"use strict";

/**
 * This class is useful when you want to optionally allow the caller to get a promise from calling a method with a
 * callback, if they want it. This is especially useful if returning a promise from a commonly used method where
 * most callers don't care about the promise.
 */
export default class Promisable {
  /**
   * @param promise {Promise} the promise to return.
   */
  constructor(promise) {
    this._promise = promise;
  }

  /**
   * @return {Promise} The promise you always wanted.
   */
  promise() {
    return this._promise;
  }
}
